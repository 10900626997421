import { Link } from "react-router-dom"
import styled from "@emotion/styled"
import PropTypes from "prop-types"

import { OpenArrowIcon } from "@ninjaone/icons"
import { sizer } from "@ninjaone/utils"
import { isRequiredIf } from "@ninjaone/utils/isRequiredIf"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils/localization"
import tokens from "@ninjaone/tokens"
import { Box } from "@ninjaone/webapp/src/js/includes/components/Styled"

import Tooltip from "./Tooltip"
import Body from "./Typography/Body"

// TODO: Remove VARIANTS once all instances removed from webapp
export const VARIANTS = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  TERTIARY: "tertiary",
  ACCESSORY: "accessory",
  DANGER: "danger",
  ICON: "icon",
}

const BUTTON_TYPES = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  TERTIARY: "tertiary",
}

const BUTTON_VARIANTS = {
  DEFAULT: "default",
  ACCESSORY: "accessory",
  DANGER: "danger",
}

// Due to layout issues the min width of the button should be set to auto until
// Design and the UX team are able to ensure that no layouts are affected by a
// min width of 80px
const BUTTON_MIN_WIDTH = "auto"

// Temporary function that should be removed after 5.8.0 once any
// buttons released up to 5.8.0 have been converted to new prop values
const v1ToV2TypeAndVariant = ({ type, variant }) => {
  if (type === "save" || type === "apply" || (type === "create" && variant !== "secondary") || variant === "primary") {
    return { buttonType: "primary", buttonVariant: "default" }
  }

  if (type === "delete") {
    return { buttonType: "secondary", buttonVariant: "danger" }
  }

  if (type === "cancel" || variant === "secondary" || (type === "create" && variant === "secondary")) {
    return { buttonType: "secondary", buttonVariant: "default" }
  }

  if (type === "close" || variant === "tertiary") {
    return { buttonType: "tertiary", buttonVariant: "default" }
  }

  if (variant === "icon") {
    return { buttonType: "tertiary", buttonVariant: "accessory" }
  }

  if (!type && variant === "accessory") {
    return { buttonType: "secondary", buttonVariant: "accessory" }
  }

  if (!type && variant === "danger") {
    return { buttonType: "primary", buttonVariant: "danger" }
  }

  return { buttonType: type, buttonVariant: variant }
}

const getColorNameByTypeAndVariant = ({ buttonType, buttonVariant, disabled, increaseContrast, transparent }) => {
  if (disabled) {
    return "colorTextDisabled"
  }

  if (buttonType === "primary") {
    return "colorTextHighContrast"
  }

  switch (buttonVariant) {
    case BUTTON_VARIANTS.ACCESSORY:
      return "colorTextStrong"
    case BUTTON_VARIANTS.DANGER:
      return "colorTextDanger"
    case BUTTON_VARIANTS.DEFAULT:
    default:
      return increaseContrast && transparent ? "colorTextActionStrong" : "colorTextAction"
  }
}

const getColorByVariant = ({ theme, buttonVariant, transparent, increaseContrast }) => {
  switch (buttonVariant) {
    case BUTTON_VARIANTS.ACCESSORY:
      return theme.colorTextStrong
    case BUTTON_VARIANTS.DANGER:
      return theme.colorTextDanger
    case BUTTON_VARIANTS.DEFAULT:
    default:
      return increaseContrast && transparent ? theme.colorTextActionStrong : theme.colorTextAction
  }
}

const getStylesByTypeAndVariant = ({ theme, buttonType, buttonVariant, transparent, compact, increaseContrast }) => {
  switch (buttonType) {
    case BUTTON_TYPES.PRIMARY:
      return `
        border-color: ${
          buttonVariant === "danger" ? `${theme.colorBackgroundCtaDanger}` : `${theme.colorBackgroundCta}`
        };
        background-color: ${
          buttonVariant === "danger" ? `${theme.colorBackgroundCtaDanger}` : `${theme.colorBackgroundCta}`
        };
        color: ${theme.colorTextHighContrast};

        & > a {
           color: ${theme.colorTextHighContrast};
        }
        
        &:hover {
          border-color: ${
            buttonVariant === "danger"
              ? `${theme.colorForegroundCtaDangerHover}`
              : `${theme.colorBackgroundAccentCtaStrongest}`
          };
          background-color: ${
            buttonVariant === "danger"
              ? `${theme.colorForegroundCtaDangerHover}`
              : `${theme.colorBackgroundAccentCtaStrongest}`
          };
        }

        &:disabled,
        &[data-disabled="true"] {
          border-color: ${theme.colorBackgroundCtaDisabled};
          background-color: ${theme.colorBackgroundCtaDisabled};
        }
      `

    case BUTTON_TYPES.TERTIARY:
      return `
        border-color: ${transparent ? "transparent" : `${theme.colorBackground}`};
        background-color: ${transparent ? "transparent" : `${theme.colorBackground}`};
        color: ${getColorByVariant({ theme, buttonVariant, transparent, increaseContrast })};

        &:hover {
          border-color: ${transparent ? "transparent" : `${theme.colorBackgroundAccentNeutralWeakest}`};
          background-color: ${transparent ? "transparent" : `${theme.colorBackgroundAccentNeutralWeakest}`};
        }

        &:disabled,
        &[data-disabled="true"] {
          border-color: ${transparent ? "transparent" : `${theme.colorBackground}`};
          background-color: ${transparent ? "transparent" : `${theme.colorBackground}`};
        }

        ${
          compact
            ? `
          height: 22px;
          padding: 0 ${tokens.spacing[1]};
        `
            : ""
        }
      `

    case BUTTON_TYPES.SECONDARY:
    default:
      return `
        border-color: ${theme.colorBorderWeak};
        background-color: ${transparent ? "transparent" : `${theme.colorBackground}`};
        color: ${getColorByVariant({ theme, buttonVariant, transparent, increaseContrast })};

        &:hover {
          background-color: ${theme.colorBackgroundAccentNeutralWeakest};
        }

        &:disabled,
        &[data-disabled="true"] {
          border-color: ${theme.colorBorderWeakest};
          background-color: ${transparent ? "transparent" : `${theme.colorBackground}`};
        }
      `
  }
}

const getIconMaxWidth = ({ compact, size }) => {
  return compact || size === "xs" || size === "sm" ? "32px" : "38px"
}

const StyledButton = styled.button`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${tokens.spacing[2]};
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "fit-content")};
  min-width: ${({ isIconBtn, overrideMinWidth, usesDeprecatedChildren }) =>
    usesDeprecatedChildren || isIconBtn || overrideMinWidth ? "auto" : BUTTON_MIN_WIDTH};
  max-width: ${({ isIconBtn, compact, size }) => isIconBtn && getIconMaxWidth({ compact, size })};
  height: ${({ compact, size }) => (compact || size === "xs" || size === "sm" ? "32px" : "38px")};
  border: 1px solid;
  border-radius: ${tokens.borderRadius[1]};
  padding: ${({ reducedPadding }) => sizer(0, reducedPadding ? 1 : 3)};
  font-family: ${tokens.typography.fontFamily.primary};
  font-size: ${tokens.typography.fontSize.body};
  user-select: none;
  cursor: pointer;

  ${({ offset }) => {
    let styles = ""
    if (!offset) return
    if (offset.left) styles += `margin-left: ${offset.left};`
    if (offset.right) styles += `margin-right: ${offset.right};`
    if (offset.top) styles += `margin-top: ${offset.top};`
    if (offset.bottom) styles += `margin-bottom: ${offset.bottom};`
    return styles
  }}

  ${({ theme, buttonType, buttonVariant, transparent, compact, increaseContrast }) => {
    return `
      ${getStylesByTypeAndVariant({ theme, buttonType, buttonVariant, transparent, compact, increaseContrast })}
    `
  }}  

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
    outline-offset: -2px;
  }

  &:disabled,
  &[data-disabled="true"] {
    cursor: not-allowed;
    color: ${({ theme }) => theme.colorTextDisabled};
  }
`

const StyledButtonDiv = StyledButton.withComponent("div")
const StyledButtonLink = StyledButton.withComponent("span")

function getButtonText(type) {
  switch (type) {
    case "apply":
      return localized("Apply")
    case "save":
      return localized("Save Changes")
    case "close":
      return localized("Close")
    case "cancel":
      return localized("Cancel")
    default:
      return ""
  }
}
export default function Button({
  asLink,
  children,
  className,
  compact,
  customTooltipPosition,
  disabled,
  form,
  fullWidth,
  id,
  dataTestid,
  Icon,
  iconLabel,
  iconPlacement,
  iconSize,
  increaseContrast,
  labelText,
  labelToken,
  linkTo,
  maxHeight,
  overrideMinWidth,
  offset,
  onClick,
  onMouseDown,
  onKeyDown,
  reducedPadding,
  renderAsDiv,
  size,
  submit,
  tooltip,
  tooltipPosition,
  tooltipAlignment,
  transparent,
  type,
  useDropdownIcon,
  dropdownIconDirection,
  variant,
  radixProps,
}) {
  const buttonText = labelText || (labelToken ? localized(labelToken) : getButtonText(type))
  const isIconBtn = !!Icon && !buttonText
  const StyledButtonElement = renderAsDiv ? StyledButtonDiv : StyledButton
  const usesDeprecatedChildren = !!children
  const { buttonType, buttonVariant } = v1ToV2TypeAndVariant({ type, variant })
  const typographyColor = getColorNameByTypeAndVariant({
    buttonType,
    buttonVariant,
    disabled,
    increaseContrast,
    transparent,
  })

  const buttonContent = children ?? (
    <>
      {Icon && iconPlacement === "left" && (
        <Icon color={typographyColor} size={iconSize} label={isIconBtn && !tooltip && iconLabel} />
      )}

      {buttonText && (
        <Body as="span" color={typographyColor} fontWeight={tokens.typography.fontWeight.medium}>
          {buttonText}
        </Body>
      )}

      {Icon && iconPlacement === "right" && (
        <Icon color={typographyColor} size={iconSize} label={isIconBtn && !tooltip && iconLabel} />
      )}
      {useDropdownIcon && <OpenArrowIcon color={typographyColor} direction={dropdownIconDirection} size="sm" />}
    </>
  )

  // TODO: Refactor in NJ-48842
  if (tooltip) {
    return (
      <Box display={fullWidth ? "block" : "inline-block"}>
        <Tooltip label={tooltip} position={tooltipPosition} align={tooltipAlignment} sideOffset={4}>
          <StyledButtonElement
            data-testid={dataTestid}
            aria-label={tooltip}
            {...{
              id,
              buttonVariant,
              onClick,
              onMouseDown,
              onKeyDown,
              size,
              offset,
              maxHeight,
              overrideMinWidth,
              ...(renderAsDiv ? { "data-disabled": disabled } : { disabled }),
              buttonType,
              compact,
              fullWidth,
              isIconBtn,
              transparent,
              reducedPadding,
              usesDeprecatedChildren,
              increaseContrast,
              ...radixProps,
            }}
          >
            {buttonContent}
          </StyledButtonElement>
        </Tooltip>
      </Box>
    )
  }

  // TODO: Refactor in NJ-48836
  if (linkTo) {
    return (
      <Link to={linkTo} {...{ disabled }}>
        <StyledButtonElement
          data-testid={dataTestid}
          {...{
            id,
            size,
            buttonVariant,
            ...(renderAsDiv ? { "data-disabled": disabled } : { disabled }),
            className,
            overrideMinWidth,
            maxHeight,
            offset,
            onMouseDown,
            onKeyDown,
            buttonType,
            compact,
            fullWidth,
            isIconBtn,
            reducedPadding,
            transparent,
            usesDeprecatedChildren,
            increaseContrast,
            ...radixProps,
            ...(!renderAsDiv && { type: "button" }),
          }}
        >
          {buttonContent}
        </StyledButtonElement>
      </Link>
    )
  }

  // <button> inside of <a> is not valid html.
  // This implementation should be used when placed within <Link> from react-router-dom
  if (asLink) {
    return (
      <StyledButtonLink
        data-testid={dataTestid}
        {...{
          className, // TODO: Remove from any implementations, then delete this prop
          compact,
          disabled,
          ...(renderAsDiv ? { "data-disabled": disabled } : { disabled }),
          fullWidth,
          id,
          isIconBtn,
          maxHeight, // TODO: Remove from any implementations, then delete this prop
          overrideMinWidth,
          offset,
          onClick,
          onMouseDown,
          onKeyDown,
          reducedPadding,
          size, // TODO: Remove from any implementations, then delete this prop
          transparent,
          _type: type,
          variant,
          increaseContrast,
        }}
      >
        {buttonContent}
      </StyledButtonLink>
    )
  }

  return (
    <StyledButtonElement
      data-testid={dataTestid}
      {...{
        id,
        size, // TODO: Remove from any implementations, then delete this prop
        form,
        buttonVariant,
        onClick,
        ...(renderAsDiv ? { "data-disabled": disabled } : { disabled }),
        className, // TODO: Remove from any implementations, then delete this prop
        maxHeight, // TODO: Remove from any implementations, then delete this prop
        overrideMinWidth,
        offset,
        onMouseDown,
        onKeyDown,
        buttonType,
        compact,
        fullWidth,
        isIconBtn,
        reducedPadding,
        transparent,
        usesDeprecatedChildren,
        increaseContrast,
        ...radixProps,
        ...(!renderAsDiv && { type: submit ? "submit" : "button" }),
      }}
    >
      {buttonContent}
    </StyledButtonElement>
  )
}

Button.defaultProps = {
  iconPlacement: "left",
  iconSize: "sm",
  tooltipAlignment: "center",
  variant: "default",
  dropdownIconDirection: "down",
}

Button.propTypes = {
  /**
   * Determines the markup of the component. Use when the component is wrapped
   * by `<Link>` from `react-router-dom`.
   */
  asLink: PropTypes.bool,
  /**
   * The height of the button
   */
  compact: PropTypes.bool,
  /**
   * The disabled state for the button
   */
  disabled: PropTypes.bool,
  /**
   * Allows button to expand full width
   */
  fullWidth: PropTypes.bool,
  /**
   * The icon for the button. May be used with or without `labelToken`.
   */
  Icon: PropTypes.elementType,
  /**
   * The visually hidden text for an icon button. Prop is required for icon buttons that do
   * not use a tooltip.
   */
  iconLabel: isRequiredIf(
    PropTypes.string,
    props => props.Icon && !props.labelToken && !props.labelText && !props.tooltip,
    "`iconLabel` is required when `labelToken`, `labelText` and `tooltip` are undefined",
  ),
  /**
   * The horizontal placement for the Icon
   */
  iconPlacement: PropTypes.oneOf(["left", "right"]),
  /**
   * The size of the icon
   */
  iconSize: PropTypes.oneOf(["sm", "md"]),
  /**
   * The id of the button
   */
  id: PropTypes.string,
  /**
   * Thet color of the text within the component. Used in conjunction with
   * `variant: default` and `transparent` when the color contrast of the text
   *  must be increased to meet minimum accessibility ratio.
   */
  increaseContrast: PropTypes.bool,
  /**
   * The text for the button
   */
  labelText: PropTypes.string,
  /**
   * The token for the button
   */
  labelToken: PropTypes.string,
  /**
   * The offset for the button in pixels. The offset is applied to the button
   * using the `margin` property.
   */
  offset: PropTypes.shape({
    left: PropTypes.string,
    right: PropTypes.string,
    top: PropTypes.string,
    bottom: PropTypes.string,
  }),
  /**
   * The function run when the button is clicked
   */
  onClick: PropTypes.func,
  /**
   * The function run when the button is clicked
   */
  onMouseDown: PropTypes.func,
  /**
   * The function run when the user presses a key
   */
  onKeyDown: PropTypes.func,
  /**
   * Overrides the min width of the button. This props should only be used when
   * the default min width (80px) causes layout issues.
   */
  overrideMinWidth: PropTypes.bool,
  /**
   * Props from Radix when component acts as a trigger for another Radix UI
   * component. This prop is not user defined.
   */
  radixProps: PropTypes.object,
  /**
   * Reduces the horizontal padding from 12px to 4px.
   */
  reducedPadding: PropTypes.bool,
  /**
   * Determines if the component should render as a `<div>`.
   */
  renderAsDiv: PropTypes.bool,
  /**
   * Toggles if the button is a submit button
   */
  submit: PropTypes.bool,
  /**
   * The tooltip content for the button
   */
  tooltip: PropTypes.string,
  /**
   * The alignment of the tooltip
   */
  tooltipAlignment: PropTypes.oneOf(["start", "center", "end"]),
  /**
   * The posiiton of the tooltip
   */
  tooltipPosition: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  /**
   * Determines if the background for secondary and tertiary buttons should be
   * transparent or use the theme's background color.
   */
  transparent: PropTypes.bool,
  /**
   * The style of the button.
   * `apply`, `save`, `close`, `cancel`, `create`, `delete`, and `default`
   * have been depreated, and are only included for backwards compatibility.
   */
  type: PropTypes.oneOf([
    "primary",
    "secondary",
    "tertiary",
    "apply",
    "save",
    "close",
    "cancel",
    "create",
    "delete",
    "default",
  ]),
  /**
   * The icon used by Dropdown triggers. This prop enables the only valid
   * instance of a secondary icon inside a button and the icon is not editable.
   */
  useDropdownIcon: PropTypes.bool,
  /**
   * The direction of the dropdown icon
   */
  dropdownIconDirection: PropTypes.oneOf(["up", "right", "down", "left"]),
  /**
   * The color of the button or button text depending on `type`.
   * `primary`, `secondary`, and `tertiary` have been deprecated,
   * and are only included for backwards compatibility.
   */
  variant: PropTypes.oneOf(["default", "danger", "accessory", "primary", "secondary", "tertiary", "icon"]),
  /**
   * Deprecated - use `compact` instead
   * @deprecated This prop should not be used, use compact instead
   */
  size: PropTypes.oneOf(["xs", "sm"]),
  /**
   * Deprecated - design system components will not include `react-router-dom` elements
   * @deprecated This prop should not be used, anchors should not contian button elements
   */
  linkTo: PropTypes.string,
  /**
   * Deprecated - css should not style design system buttons
   * @deprecated This prop should not be used,
   */
  className: PropTypes.string,
  /**
   * Deprecated - use `labelToken` and `Icon` for UI elements, and `onClick` and `onMouseOver` for functionality
   * @deprecated This prop should not be used,
   */
  children: PropTypes.node,
  /**
   * Deprecated - use `compact` instead
   * @deprecated This prop should not be used, use compact to adjust button height
   */
  maxHeight: PropTypes.string,
}
