import { useEffect, useState } from "react"
import fastDeepEqual from "fast-deep-equal"

export const useIsDirty = (initialValues, values) => {
  const [isDirty, setIsDirty] = useState(false)

  useEffect(() => {
    setIsDirty(!fastDeepEqual(initialValues, values))
  }, [initialValues, values])

  return isDirty
}
