import React, { useEffect, useMemo, useRef } from "react"
import { Virtualizer } from "virtua"

import styled from "@emotion/styled"
import isPropValid from "@emotion/is-prop-valid"

import { ComboboxList, SelectGroup, SelectGroupLabel, useSelectContext } from "@ariakit/react"

import tokens from "@ninjaone/tokens"
import { ErrorIconSvg, MagnifyingGlass } from "@ninjaone/icons"

import { localized } from "@ninjaone/webapp/src/js/includes/common/utils"

import { shouldIgnoreProps } from "../utils"
import { useRestoreScroll, useScrollStatus } from "../hooks"
import {
  comboboxHeight,
  emptyStatePopoverHeight,
  emptyStatePopoverWidth,
  maxOptionsToHideCombobox,
  popoverMaxHeight,
} from "../constants"

import Body from "../../Typography/Body"

import SelectOption from "./SelectOption"
import SelectLoading from "./SelectLoading"

const StyledSelectGroupLabel = styled(SelectGroupLabel)`
  display: flex;
  align-items: center;

  text-transform: uppercase;

  line-height: ${tokens.typography.lineHeight};
  font-size: ${tokens.typography.fontSize.bodyXs};
  color: ${({ theme }) => theme.colorTextWeakest};

  padding: ${tokens.spacing[2]} ${tokens.spacing[3]} 0 ${tokens.spacing[3]};
`

const StyledSelectGroup = styled(SelectGroup, {
  shouldForwardProp: prop => isPropValid(prop) || shouldIgnoreProps(prop),
})`
  ${({ theme, isComboboxVisible }) => {
    if (isComboboxVisible) {
      return `border-top: 1px solid ${theme.colorBorderWeak};`
    }

    return `
      &:not(:first-of-type) {
        border-top: 1px solid ${theme.colorBorderWeak};
      }
    `
  }}
`

const StyledEmptyState = styled("div", {
  shouldForwardProp: prop => isPropValid(prop) || shouldIgnoreProps(prop),
})`
  width: ${({ sameWidth }) => (sameWidth ? "100%" : emptyStatePopoverWidth)};
  height: ${emptyStatePopoverHeight};

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${tokens.spacing[1]};
`

const VirtualizerContainer = styled.div`
  overflow-y: auto;
  overflow-anchor: none;

  max-height: calc(${popoverMaxHeight} - ${comboboxHeight} - 2px);
`

function OptionsContainer({ children, options, selectedValue, onScrollStatusChange, isGrouped }) {
  const virtualizedListRef = useRef()
  const listContainerRef = useRef()

  // We have some issues with the virtualization with the grouped options. We need to disable it for now.
  const isVirtualizedEnabled = options.length > maxOptionsToHideCombobox && !isGrouped

  const { selectedValueIndexes } = useRestoreScroll({
    virtualizedListRef,
    listContainerRef,
    options,
    selectedValue,
    enabled: isVirtualizedEnabled,
  })

  const {
    isAtTop,
    scrolling,
    range,
    scrollTop,
    isAtBottom,
    handleOnScroll,
    handleOnScrollEnd,
    handleOnRangeChange,
  } = useScrollStatus({ virtualizedListRef, options })

  useEffect(() => {
    onScrollStatusChange?.({ isAtTop, isAtBottom, scrolling, range, scrollTop })
  }, [isAtTop, isAtBottom, scrolling, range, onScrollStatusChange, scrollTop])

  return isVirtualizedEnabled ? (
    <VirtualizerContainer {...{ ref: listContainerRef }}>
      <Virtualizer
        {...{
          ref: virtualizedListRef,
          keepMounted: selectedValueIndexes,
          onScroll: handleOnScroll,
          onScrollEnd: handleOnScrollEnd,
          onRangeChange: handleOnRangeChange,
        }}
      >
        {children}
      </Virtualizer>
    </VirtualizerContainer>
  ) : (
    children
  )
}

function SelectOptions({ valueSelectorKey, labelSelectorKey, isMulti, options, selectedValue, onScrollStatusChange }) {
  const selectOptions = useMemo(
    () =>
      options.map((option, index) => (
        <SelectOption
          {...{ key: option[valueSelectorKey], isMulti, index, valueSelectorKey, labelSelectorKey, ...option }}
        />
      )),
    [isMulti, options, valueSelectorKey, labelSelectorKey],
  )

  return <OptionsContainer {...{ options, selectedValue, onScrollStatusChange }}>{selectOptions}</OptionsContainer>
}

function SelectGroupOptions({
  valueSelectorKey,
  labelSelectorKey,
  isComboboxVisible,
  isMulti,
  options,
  selectedValue,
  onScrollStatusChange,
}) {
  const selectOptions = useMemo(
    () =>
      options.map(([group, groupOptions], index) => (
        <React.Fragment {...{ key: group }}>
          <StyledSelectGroup {...{ isComboboxVisible }}>
            {group && group !== "undefined" && <StyledSelectGroupLabel>{group}</StyledSelectGroupLabel>}

            {groupOptions.map((option, groupOptionIndex) => (
              <SelectOption
                {...{
                  key: option[valueSelectorKey],
                  isMulti,
                  valueSelectorKey,
                  labelSelectorKey,
                  index: groupOptionIndex,
                  ...option,
                }}
              />
            ))}
          </StyledSelectGroup>
        </React.Fragment>
      )),
    [isComboboxVisible, isMulti, options, valueSelectorKey, labelSelectorKey],
  )

  return (
    <OptionsContainer {...{ options, selectedValue, onScrollStatusChange, isGrouped: true }}>
      {selectOptions}
    </OptionsContainer>
  )
}

export function SelectOptionsList({
  valueSelectorKey,
  labelSelectorKey,
  creatable,
  groupKey,
  isComboboxVisible,
  isMulti,
  loading,
  options,
  sameWidth,
  noOptionsText,
  onScrollStatusChange,
  errorMessage,
}) {
  const selectStore = useSelectContext()
  const selectedValue = selectStore.useState("value")

  const emptyStateText = useMemo(() => {
    if (errorMessage) {
      return typeof errorMessage === "boolean" ? localized("Unable to fetch options") : errorMessage
    }

    if (noOptionsText) return noOptionsText

    return creatable ? localized("No match found") : localized("No options found")
  }, [creatable, errorMessage, noOptionsText])

  const commonOptionListProps = {
    valueSelectorKey,
    labelSelectorKey,
    isMulti,
    options,
    selectedValue,
    onScrollStatusChange,
  }
  if (loading) return <SelectLoading {...{ isMulti, sameWidth }} />

  return (
    <ComboboxList>
      {!!options.length && !errorMessage ? (
        groupKey ? (
          // If we have groupKey, it means that is grouped.
          <SelectGroupOptions {...{ ...commonOptionListProps, isComboboxVisible }} />
        ) : (
          <SelectOptions {...commonOptionListProps} />
        )
      ) : (
        <StyledEmptyState {...{ sameWidth }}>
          {!errorMessage ? <MagnifyingGlass /> : <ErrorIconSvg />}

          <Body {...{ color: "colorTextWeak", type: "bodyXs", fontWeight: tokens.typography.fontWeight.medium }}>
            {emptyStateText}
          </Body>

          {creatable && !errorMessage && (
            <Body {...{ color: "colorTextWeak", type: "bodyXs", marginTop: tokens.spacing[1] }}>
              {localized("Press Enter to create option")}
            </Body>
          )}
        </StyledEmptyState>
      )}
    </ComboboxList>
  )
}
