import { useCallback, useEffect, useMemo, useState } from "react"

import styled from "@emotion/styled"
import { useSelectContext } from "@ariakit/react"

import tokens from "@ninjaone/tokens"

import { localized } from "@ninjaone/webapp/src/js/includes/common/utils"
import { Flex } from "@ninjaone/webapp/src/js/includes/components/Styled"

import { SINGLE_SELECT_DISPLAY_TYPE, arrowIconWidth, secondActionIconWidth } from "../constants"

import Tags from "../../Tags"
import Body from "../../Typography/Body"

const StyledSelectValue = styled.div`
  max-width: ${({ errorMessage }) => {
    return errorMessage ? `calc(100% - ${arrowIconWidth} - ${secondActionIconWidth})` : `calc(100% - ${arrowIconWidth})`
  }};
`

export function SelectValue({
  valueSelectorKey,
  labelSelectorKey,
  disabled,
  isMulti,
  placeholderText,
  selectedValueDisplay,
}) {
  const selectStore = useSelectContext()

  const selectedValue = selectStore.useState("value")
  const options = selectStore.useState("items")

  const [selectedValueOption, setSelectedValueOption] = useState([])

  // Always work with an array to simplify logic
  const valuesArray = useMemo(() => (Array.isArray(selectedValue) ? selectedValue : [selectedValue]), [selectedValue])
  const getSelectedOption = useCallback(value => options.find(option => option[valueSelectorKey] === value), [
    options,
    valueSelectorKey,
  ])

  useEffect(() => {
    if (isMulti) {
      if (!valuesArray.length) {
        setSelectedValueOption([])
      } else {
        setSelectedValueOption(prevState => {
          const newOptions = valuesArray.filter(
            option => !prevState.some(prevOption => prevOption?.[valueSelectorKey] === option),
          )

          if (newOptions.length >= 0) {
            const parsedNewOptions = newOptions.map(option => getSelectedOption(option))

            return [...prevState, ...parsedNewOptions]
          }

          return prevState
        })
      }
    } else {
      setSelectedValueOption(valuesArray.map(value => getSelectedOption(value)))
    }
  }, [getSelectedOption, isMulti, valuesArray, valueSelectorKey])

  const selectedOptionsArray = valuesArray.reduce((acc, value) => {
    const selectedOption = selectedValueOption.find(option => option?.[valueSelectorKey] === value)

    if (selectedOption) {
      acc.push(selectedOption)
    }

    return acc
  }, [])

  const handleRemoveTag = selectedTagValue => {
    const newSelectedValue = selectedValue.filter(value => value !== selectedTagValue)
    selectStore.setValue(newSelectedValue)
  }

  const renderCustomSelectedValueLabel = () => {
    const commonSelectedValueBodyProps = {
      as: "p",
      type: "body",
      color: disabled ? "colorTextDisabled" : "colorTextStrong",
    }

    // Handle case where no labels are found
    if (!selectedOptionsArray.length) {
      return (
        <Body {...{ color: disabled ? "colorTextDisabled" : "colorTextWeakest", type: "headingXs" }}>
          {placeholderText || localized("Select")}
        </Body>
      )
    }

    // Single select scenario
    if (!isMulti) {
      const selectedOption = getSelectedOption(selectedValue)

      if (selectedValueDisplay === SINGLE_SELECT_DISPLAY_TYPE.ICON) {
        return <Flex alignItems="center">{selectedOption.icon}</Flex>
      }

      if (selectedValueDisplay === SINGLE_SELECT_DISPLAY_TYPE.ICON_WITH_TEXT) {
        return (
          <Flex alignItems="center" gap={tokens.spacing[2]}>
            <Body {...commonSelectedValueBodyProps}>{selectedOption.icon}</Body>
            <Body {...commonSelectedValueBodyProps}>{selectedOption[labelSelectorKey]}</Body>
          </Flex>
        )
      }

      return (
        <Body {...commonSelectedValueBodyProps}>
          {selectedOptionsArray[selectedOptionsArray.length - 1][labelSelectorKey]}
        </Body>
      )
    }

    // Default multi-select scenario
    const parsedTagLabels = selectedOptionsArray.map((selectedOption, index) => ({
      label: selectedOption[labelSelectorKey],
      id: `${index}-${selectedOption[labelSelectorKey]}-tag`,
      variant: disabled ? "disabled" : "default",
      onRemoveClick: event => {
        handleRemoveTag(selectedOption[valueSelectorKey])

        event.stopPropagation()
      },
    }))

    return (
      <Tags
        {...{
          labels: parsedTagLabels,
          maxItemsDisplayed: 20,
          showRemainingItemsTooltip: false,
        }}
      />
    )
  }

  return <StyledSelectValue>{renderCustomSelectedValueLabel()}</StyledSelectValue>
}
